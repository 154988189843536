import Image from "next/image";
import { Col, Container, Row } from "react-bootstrap";
import dynamic from "next/dynamic";

const ContactForm = dynamic(() => import("@/components/ContactForm"));

interface ContactProps {
  type?: string;
  title?: string;
  className?: string;
  image?: string;
  country?: string;
}

const Contact = ({
  type = "banner",
  title = "Contact us",
  className = "",
  image = "/assets/images/contact-lastss.jpg",
  country
}: ContactProps) => {
  return (
    <div
      className={
        type === "banner"
          ? `contact ${className}`
          : `contact-fixed ${className}`
      }
    >
      <Container>
        <Row className="justify-content-between gx-5-rem">
          <Col xs={12}>
            <h3 className="heading mb-4-rem">{title}</h3>
          </Col>
          <Col lg={6}>
            <Image
              src={country ? `/assets/images/${country}-contact-us.jpg`: image}
              width={3998}
              height={2666}
              priority={false}
              alt="Contact"
              style={{ objectFit: "cover" }}
              className="img-fluid"
            />
            <p>
              {`What's`} on your mind? Tell us what {`you're`} looking for and{" "}
              {`we'll`} connect you to the right people.
            </p>
          </Col>
          <Col lg={6}>
            <p className="h6 mb-25-rem-fixed mt-5 mt-lg-0">{`Let's discuss your project.`}</p>
            {/* <HubSpotForm type='contact' /> */}
            <ContactForm type="contact" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
